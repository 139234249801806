import React from 'react';
import Container from '../components/core/Container';
import PageTitle from '../elements/PageTitle';
import PageBody from '../elements/PageBody';
import DarkButton from '../elements/DarkButton';
import InternalLink from '../elements/Link/InternalLink';
import CallToAction from '../components/core/CallToAction';
import Layout from '../components/core/Layout';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <PageTitle>Page not found</PageTitle>
      <PageBody>
        <p>
          We are sorry, the page you have requested could not be found. It may have been moved or no
          longer exists.
        </p>
        <InternalLink to="/">
          <CallToAction as={DarkButton} text="Back to the homepage" />
        </InternalLink>
      </PageBody>
    </Container>
  </Layout>
);

export default NotFoundPage;
