import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const InternalLink = ({ className, ...props }) => (
  <Link className={`${styles.link} ${className}`} {...props} />
);

InternalLink.defaultProps = {
  className: '',
};

InternalLink.propTypes = {
  className: PropTypes.string,
};

export default InternalLink;
